const Prompts = [
    {t:'Walk away from something that feels like trouble', p:'walked away from something that felt like trouble',y:'walked away from something that felt like trouble'},
    {t:'Move towards the sun', p:'moved towards the sun', y:'moved towards the sun'},
    {t:'Find somewhere to sit, linger there for a while', p:'found somewhere to sit and lingered', y:'found somewhere to sit and lingered'},
    {t:'Find a corner to linger on', p:'lingered on a corner', y:'lingered on a corner'},
    {t:'Avoid eye contact from those around you', p:'avoided eye contact', y:'avoided eye contact'},
    {t:'Walk away from a place where you\'ve been hurt', p:'walked away from a place they\'ve been hurt', y:'walked away from a place you\'ve been hurt'},
    {t:'Walk towards a place where you aspire to be', p:'walked towards a place they aspire to be', y:'walked towards a place you aspire to be'},
    {t:'Look for your favorite color', p:'looked for their favorite color', y:'looked for your favorite color'},
    {t:'Go towards something shiny', p:'went towards something shiny', y:'went towards something shiny'},
    {t:'Find flowers, real or fake', p:'searched for flowers', y:'searched for flowers'},
    {t:'Stretch your arms out, walk in the direction of your longer arm', p:'walked in the direction of their longer arm', y:'walked in the direction of your longer arm'},
    {t:'Take a breath, walk towards the best smell', p:'walked towards a nice smell', y:'walked towards a nice smell'},
    {t:'Walk away from a representation of authority', p:'walked away from authority', y:'walked away from authority'},
    {t:'Walk towards noise', p:'followed a noise', y:'followed a noise'},
    {t:'Walk in the direction of litter on the sidewalk', p:'followed litter on the sidewalk', y:'followed litter on the sidewalk'},
    {t:'Walk towards a construction site, notice what\'s changing', p:'searched for a construction site', y:'searched for a construction site'},
    {t:'Look for a new building near you', p:'searched for new buildings', y:'searched for new buildings'},
    {t:'Look for an old buildings, why is it still there?', p:'searched for an old building', y:'searched for an old building'},
    {t:'Head towards a quieter place', p:'went towards a quiet place', y:'went towards a quiet place'},
    {t:'Walk half as quickly', p:'walked half as quickly', y:'walked half as quickly'},
    {t:'Walk as if you were feeling gentle', p:'walked as if they were gentle', y:'walked as if you were gentle'},
    {t:'Walk as if you were powerful', p:'walked as if they were powerful', y:'walked as if you were powerful'},
    {t:'Walk like the person you admire most', p:'walked like the person they admire most', y:'walked like the person you admire most'},
    {t:'Make up secrets about the people around you', p:'made up secrets about the people around them', y:'made up secrets about the people around you'},
    {t:'Walk as if there was no one else around', p:'walked as if there was no one else around', y:'walked as if there was no one else around'},
    {t:'Walk as if you have a right to be here', p:'walked as if they had a right to be here', y:'walked as if you had a right to be here'},
    {t:'Look for beautiful buildings', p:'searched for beautiful buildings', y:'searched for beautiful buildings'},
    {t:'Find buildings that look out of place', p:'found buildings that looked out of place', y:'found buildings that looked out of place'},
    {t:'Find a place that is open to everyone', p:'found a place that\'s open to everyone', y:'found a place that\'s open to everyone'},
    {t:'Walk towards a place where you feel good', p:'walked towards a place they felt good', y:'walked towards a place you felt good'},
    {t:'Look into windows, find colors there', p:'looked for colors in windows', y:'looked for colors in windows'},
    {t:'Find a corner that you find funny', p:'found a funny corner', y:'found a funny corner'},
    {t:'Find a sign that makes you laugh', p:'found a funny sign', y:'found a funny sign'},
    {t:'Follow the next bus that you see', p:'followed a bus', y:'followed a bus'},
    {t:'Find something rough', p:'searched for something rough', y:'searched for something rough'},
    {t:'Find something soft', p:'searched for something soft', y:'searched for something soft'},
    {t:'Walk as if you were tough', p:'walked as if they were tough', y:'walked as if you were tough'},
    {t:'Find a spot on the sidewalk to linger on', p:'lingered on the sidewalk', y:'lingered on the sidewalk'},
    {t:'Walk towards a bright light', p:'walked towards a bright light', y:'walked towards a bright light'},
    {t:'Look up, go towards tall buildings', p:'went towards tall buildings', y:'went towards tall buildings'},
    {t:'Go where your favorite person would go', p:'went towards a place their favorite person would go', y:'went where you favorite person would go'},
    {t:'Search for your reflection', p:'searched for their reflection', y:'searched for your reflection'},
    {t:'Turn towards geometric shapes', p:'turned towards geometrics shapes', y:'turned towards geometrics shapes'},
    {t:'Find an abandoned building', p:'found an abandoned building',y:'found an abandoned building'},
    {t:'Walk towards a place you don\'t understand', p:'walked towards a place they didn\'t understand', y:'walked towards a place you didn\'t understand'},
    {t:'Head in the direction of a place you know well', p:'went towards a familiar place', y:'went towards a familiar place'},
    {t:'Head towards a colorful street', p:'found a colorful street', y:'found a colorful street'},
    {t:'Go where you would have gone when you were 13', p:'went towards where their 13 year old self would have gone', y:'went towards where you would have gone as a 13 year old'},
    {t:'Turn to a crowded area', p:'searched for a crowded area', y:'searched for a crowded area'},
    {t:'Search for repeating patterns', p:'found repeating patterns', y:'found repeating patterns'},
    {t:'Search for a public building you can walk into', p:'walked into a public building' , y:'walked into a public building'},
    {t:'Find a place to watch people come and go', p:'found a place where people come and go', y:'found a place where people come and go'},
    {t:'Find a place to watch people laugh', p:'found a place where people laugh', y:'found a place where people laugh'},
    {t:'Find a place close enough to listen to people', p:'found a place to listen to people', y:'found a place to listen to people'},
    {t:'Turn towards a curvy street', p:'searched for a curvy street', y:'searched for a curvy street'},
    {t:'Search for gated buildings', p:'searched for gated buildings', y:'searched for gated buildings'},
    {t:'Search for the tallest tree', p:'found the tallest treet', y:'found the tallest treet'},
    {t:'Search for a sign for something that no longer exists', p:'searched for a sign of something that no longer exists', y:'searched for a sign of something that no longer exists'}
];

export { Prompts }